<template>
  <div class="oferta">
    <div class="oferta-container">
      <b-row>
        <b-col>
          <h1>
            {{ $t("oferta") }}
          </h1>
          <!-- <h5 style="text-transform:uppercase"> {{ $t('usercontract') }} </h5> -->
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <div
            v-if="lang == 'ru'"
            class="oferta-card"
            style="padding-left:20px;padding-right:20px"
          >
            <p>
              Мазкур ҳужжат Ўзбекистон Республикаси Президенти ҳузуридаги
              Тадбиркорлик субъектларининг ҳуқуқлари ва қонуний манфаатларини
              ҳимоя қилиш бўйича вакилининг “ЯГОНА ДАВЛАТ НАЗОРАТИ” ахборот
              тизимидан (кейинги ўринларда - Тизим) тадбиркорларнинг рўйхатдан
              ўтиши, шахсий кабинет очиши ва ундан фойдаланиш бўйича Ўзбекистон
              Республикаси Фуқаролик кодексининг 367-моддаси ва 369-моддаси
              иккинчи қисмига мувофиқ хизмат кўрсатиш шартномасини (кейинги
              ўринларда – Шартнома) тузиш юзасидан лаёқатли бўлган
              тадбиркорларга (кейинги ўринларда – Фойдаланувчи) расмий таклиф
              ҳисобланади. <br />
              Фойдаланувчи томонидан мазкур офертада белгиланган ҳаракатларнинг
              бажарилиши, у томонидан қуйидаги шартлар сўзсиз қабул
              қилинганлигини англатади (Офертанинг акцепти). Офертани акцепт
              қилган пайтдан шартнома тузилган ҳисобланади. Мазкур шартномада
              тўғридан-тўғри назарда тутилмаган барча ҳолларда, томонлар
              Ўзбекистон Республикаси қонун ҳужжатлари ва иш муомаласи
              одатларига риоя қиладилар.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              1. АТАМА ВА ТАЪРИФЛАР.
            </p>
            <p class="mt-0 pt-0">
              1.1. Мазкур шартномада қуйидаги атама ва таърифлар қўлланилади:
              <br /><span style=" font-weight: bold;"
                >Шахсга доир маълумотларни қайта ишлаш</span
              >
              – шахсга доир маълумотларни йиғиш, тизимлаштириш, сақлаш,
              ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш,
              узатиш, эгасизлантириш ва йўқ қилиш бўйича битта ёки бир нечта
              ҳаракатларни амалга ошириш; <br /><span
                style=" font-weight: bold;"
                >Шахсга доир маълумотлар</span
              >
              – Тизимдан фойдаланиш учун фойдаланувчи томонидан тақдим этилган
              муайян жисмоний шахсга тааллуқли бўлган ёки уни идентификация
              қилиш имконини берадиган ахборот; <br /><span
                style=" font-weight: bold;"
              >
                Хизмат кўрсатувчи Хизмат кўрсатувчи</span
              >- фойдаланувчига  <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзили орқали тадбиркорлик
              субъектлари фаолияти бўйича ваколатли органлар томонидан
              ўтказиладиган текширишлар тўғрисидаги ҳамда уларнинг ҳуқуқ ва
              манфаатларини ҳимоя қилиш билан боғлиқ маълумотларни жойлаштириб
              борувчи ташкилот; <br /><span style=" font-weight: bold;"
                >Фойдаланувчи</span
              >
              – мазкур оферта шартларини қабул қилган ва  <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орақали хизматларни олувчи лаёқатли жисмоний шахс.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              2. АКЦЕПТ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              2.1. Мазкур Офертанинг акцепти  <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти орқали
              авторизациядан ўтиб, хизмат кўратувчи томонидан кўрсатиладиган
              хизматларни олиш орқали амалга оширилади. <br />
              2.2. Мазкур Шартномани тузиш орқали фойдаланувчи хизмат
              курсатувчига амалдаги шахсга доир маълумотлар тўғрисидаги қонун
              ҳужжатлари талабларига мувофиқ, ушбу шартномани бажариш мақсадида,
              автоматизация воситаларидан фойдаланган ёки улардан фойдаланмаган
              ҳолда, ўзининг шахсга доир маълумотларига (шу жумладан, мобиль
              телефон рақами, Ф.И.Ш., рўйхатдан ўтиш билан боғлиқ маълумотлар,
              шунингдек, бошқа шахсга доир маълумотлар) ишлов беришга муддатсиз
              розилигини беради. <br />Шунингдек, ушбу орқали фойдаланувчи
              мазкур шартнома бўйича хизмат кўрсатувчига тақдим этилган шахсга
              доир маълумотларни ушбу шартномани бажариш мақсадида учинчи
              шахсларга тақдим этишга розилик беради. <br />2.3. Хизмат
              кўрсатувчи Фойдаланувчининг шахсга доир маълумотларнинг махфийлиги
              бўйича Ўзбекистон Республикасининг амалдаги қонун ҳужжатлари
              талабларига мувофиқ барча зарур бўлган чораларни кўришини
              Фойдаланувчига кафолатлайди. Фойдаланувчи унинг шахсга доир
              маълумотларига ишлов беришга розилиги (шу жумладан учинчи
              шахсларга ҳам) муддатсиз ҳисобланиб, истаган пайтда шахсга доир
              маълумотларига ишлов беришга розилигини қайтариб олиши мумкин.
              <br />2.4. Тизимдан фойдаланишдан аввал Фойдаланувчи мазкур
              Офертанинг матни билан тўлиқ танишиб чиқиши зарур. Фойдаланувчи
              Офертанинг шартларидан тўлиқ ёки қисман норози бўлса, Тизимдан
              фойдаланишдан бош тортиши зарур.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              3. ШАРТНОМА ПРЕДМЕТИ
            </p>
            <p class="mt-0 pt-0">
              3.1. Фойдаланувчи хизмат кўрсатувчининг  <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзилида
              руйхатдан ўтади. Руйхатдан ўтиш жараёнида шахсий маълумотлар,
              тадбиркорлик субъектнинг юридик манзили ва бошқа реквизитлари
              тўлдирилади. Фойдаланувчи Рўйхатдан ўтиш ва тизимдан фойдаланиш
              пайтида берилган маълумотларнинг аниқ, тўғри ва долзарб эканлигига
              ва ушбу маълумотлардан фойдаланиш ҳуқуқига эга эканлигига кафолат
              беради. Фойдаланувчи маълумотлари ўзгарса, уларни янгилаш
              мажбуриятини олади. <br />3.2. Рўйхатдан ўтгандан кейин
              тадбиркорга шахсий кабинет очилади. Шахсий кабинетда ўз фаолиятида
              рўйхатга олинган текширишлар тўғрисида маълумотлар ҳамда давлат
              назорат функциялари, мажбурий талаблар ва текшириш ҳуқуқи эга
              бўлган мансабдор шахслар реестрлари мавжуд бўлади. Фойдаланувчи
              тизим орқали ушбу маълумотлар ва реестрлардан фойдаланиши ҳамда
              тизимдан рўйхатдан ўтиш ва фойдаланиш жараёнида тизим орқали смс
              хабарномалар олиши мумкин. <br />Шунингдек, шахсий кабинет орқали
              Фойдаланувчи ўз фаолиятида ўтказилаётган текширувларни
              Текширувларни рўйхатга олиш китобида рўйхатга олиши, текширув
              жараёни юзасидан ариза ва шикоят қолдириши, ўз фикрларини йўллай
              олиши мумкин. <br />Шунингдек тизимда видео-дарслар ва қўлланмалар
              жойлаштирилади. Тизим орқали унга жойлаштирилган ресурслардан
              фойдаланиш бепул. <br />Тизимдан фойдаланиш жаранида
              Фойдаланувчига йўлланадиган смс хабарномалар учун Фойдаланувчининг
              мобил алоқа ҳисоб рақамидан мобил алоқа операторлари ва
              контент-провайдерлар томонидан белгиланган нархларга асосан
              қўшимча тўловлар ечиб олиниши мумкин.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              4. ТАРАФЛАР МАЖБУРИЯТЛАРИ
            </p>
            <p class="mt-0 pt-0">
              4.1. Хизмат кўрсатувчи қуйидагиларга мажбур: <br />
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>
              тизимининг узлуксиз ишини таъминлаш (учинчи шахсларнинг айби билан
              юзага келадиган узилиш ҳолатлари бундан мустасно), шахсга доир
              маълумотларнинг махфийлигига риоя қилиш, уларга ноқонуний ишлов
              беришнинг олдини олиш, уларнинг яхлитлиги ва сақланишини
              таъминлайдиган ҳуқуқий, ташкилий ва техник чораларни кўриш.
              <br />4.2. Фойдаланувчи қуйидагиларга мажбур: <br />мазкур
              офертанинг талабларини бажариш, Тизимга уланувчи воситанинг
              ахборот хавфсизлигини мустақил равишда таъминлаш; ўзининг
              лаёқатлиги, шунингдек Тизимдан фирибгарлик ва бошқа ғайриқонуний
              мақсадларда фойдаланмаслигини тасдиқлаш ва кафолатлаш, Хизмат
              кўрсатувчининг розилигини олдиндан олмасдан шартнома бўйича ҳуқуқ
              ва мажбуриятларини учинчи шахсларга бермаслик. <br />4.3. Хизмат
              кўрсатувчи бир томонлама мазкур Офертага ўзгартириш киритишга
              ҳақли, бунда Хизмат кўрсатувчи Фойдаланувчини бу ҳақда Тизим ёки
              СМС-хабар орқали огоҳлантиради.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              5. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ
            </p>
            <p class="mt-0 pt-0">
              5.1. Томонлар мазкур шартноманинг талабларини бажармаганлиги ёки
              лозим даражада бажармаганлиги учун Ўзбекистон Республикаси
              қонунчилигига мувофиқ жавоб берадилар. <br />
              5.2. Фойдаланувчи мазкур шартнома бўйича ўз мажбуриятларини
              бажармаганлиги ёки лозим даражада бажармаганлиги натижасида
              вужудга келган ҳар қандай зарар ёки салбий оқибатларга ўзи жавоб
              беради. <br />
              5.3. Фойдаланувчи хавфсиз бўлмаган уланишлардан фойдаланган ҳолда,
              ёхуд зарарли дастур ва иловалар таҳдиди остида ўзининг шахсга доир
              маълумотларини қурилмага киритишида уларнинг хавфсизлигига тўлиқ
              жавоб беради. <br />
              5.4. Фойдаланувчи томонидан шартнома шартлари бажарилмаганлиги ёки
              лозим даражада бажарилмаганлиги оқибатида Хизмат кўрсатувчи
              шартномада белгиланган мажбуриятларни бажара олмаганлиги учун
              Хизмат кўрсатувчи жавобгар бўлмайди.
              <br />5.5. Фойдаланувчи шартнома шартлари билан ўз вақтида ёки
              буткул танишмаганлиги оқибатида вужудга келадиган зарар учун
              Хизмат кўрсатувчи жавобгар бўлмайди. <br />5.6. Хизмат
              кўрсатувчининг назорат доирасига кирмайдиган сабабларга кўра
              шартнома шартлари лозим даражада бажарилмаслигига Хизмат
              кўрсатувчи жавобгар бўлмайди. <br />Хизмат кўрсатувчининг назорат
              доираси фақат ушбу Тизим хизматларининг ишлаши билан чекланиб,
              учинчи шахсларнинг айби билан вужудга келган узилишларга ёхуд
              давлат органлари ёки суд томонидан қуйилган чеклов ва тақиқларни
              ўз ичига олмайди. <br />5.7. Ҳар бир Томон ушбу шартномада
              белгиланган жавобгарлик чекланганлигини тан олган ҳолда, бошқа
              Томон мазкур шартномани тузишни ва ушбу чекловлар тарафлар
              ўртасидаги келишувлар учун зарур асос эканлигини тан олади. Бунда,
              томонларнинг назоратидан ташқаридаги ҳар қандай шароитлар
              оқибатида, шу жумладан, ҳуқумат фаолияти ёки террористик
              ҳаракатлар, зилзилалар, ёнғинлар, тошқинлар ёки бошқа табиий
              офатлар, иш шароитлари билан боғлиқ вазиятлар, электр
              таъминотидаги узилишлар ва Интернетга уланишидаги бузилишлар, бу
              билан чегараланмаган ҳолда, шунга ўхшаш ҳолатларда томонларнинг
              ҳеч бири шартнома талабларини бажармаганлиги ёки бажаришни
              кечиктирганлиги учун жавоб бермайди.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              6. ШАРТНОМАНИ БЕКОР ҚИЛИШ
            </p>
            <p class="mt-0 pt-0">
              6.1. Фойдаланувчи Тизимдаги ўз
              <span style=" font-weight: bold;"
                >аккаунтини ўчириб, шахсий кабинетдан чиқиб кетган ҳолда</span
              >
              мазкур шартномани ҳар қандай вақтда бажармасликка ҳақли.
              <br />6.2. Мазкур шартнома аккаунт ўчирилган вақтдан бошлаб бекор
              қилинган деб ҳисобланади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              7. КЕЛИШМОВЧИЛИКЛАРНИ КЎРИБ ЧИҚИШ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              7.1. Мазкур шартномадан келиб чиқувчи ҳар қандай низо, ўзаро
              музокаралар орқали, ёхуд Фойдаланувчининг ёзма мурожаати асосида
              шикоят тартибида кўриб чиқилиши зарур. Мурожаатни кўриб чиқиш ва
              ва унинг мазмунига қуйиладиган талаблар амалдаги Ўзбекистон
              Республикаси қонунчилиги билан белгиланади. <br />
              7.2. Агар вужудга келган низо музокаралар ёки шикоят тартибида ҳал
              этилмаса, низо Ўзбекистон Республикасининг амалдаги қонунчилиги
              билан белгиланган тартибда жавобгар жойлашган жойдаги
              тааллуқлилиги бўйича тегишли судда кўриб чиқилиши керак. <br />
              7.3. Фойдаланувчилар шикоятлари билан боғлиқ ишлар ва техник
              кўллаб-қувватлаш хизматлари Фойдаланувчиларга фақат сўровларни
              қабул қилиш, уларга ишлов бериш ва тегишли сўровларга жавобларни
              юбориш орқали амалга оширилади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
             8. БОШҚА ШАРТЛАР
            </p>
            <p class="mt-0 pt-0">
              8.1. Тўғридан-тўғри шартномада назарда тутилмаган ҳолатлар Ўзбекистон Республикасининг амалдаги қонунчилигида белгиланган тартибда амалга оширилади. <br>
              8.2. Ушбу таклифни қабул қилиб, Фойдаланувчи ушбу шартноманинг барча шартларини сўзсиз қабул қилганлигини тасдиқлайди ва уни бажаришни ўз зиммасига олади.
            </p>


            <!-- <p>
                        1.Условия использования веб-сайта, в том числе правила регистрации, изложены в Правилах. Каждый пользователь веб-сайта обязан ознакомиться с содержанием Правил.
                    </p>
                    <p>
                        2.	Чтобы использовать веб-сайт в полном объеме, устройство, подключенное к Интернету, должно соответствовать следующим требованиям:
                            а. Бесперебойное Интернет-соединение, обеспечивающее двустороннюю связь через протокол HTTPS;
                            б. Правильно установленный и настроенный веб-браузер последней версии, поддерживающий стандарт HTML5 и технологию каскадных таблиц стилей (CSS3), например, Google Chrome, Mozilla Firefox, Opera, Microsoft Edge, Internet Explorer. 
                            в. поддержка JavaScript и файлов cookies включена (обычно в браузерах включена по умолчанию).

                    </p> 
                    <p>
                        3. Пользователем может быть только физическое лицо с соответствующим объемом дееспособности. <br><br>
                        4. Пользователь может иметь только одну учетную запись.<br><br>
                        5. Для регистрации учетной записи требуется:
                        а. Заполнить форму, доступную на веб-сайте, и предоставить необходимые данные, включая ваш номер телефона и уникальный пароль;
                        б. Ознакомиться с Правилами и приложениями к ним, принимая их условия. <br><br>
                        6. Для регистрации Пользователя используется номер мобильного телефона, на указанный номер будет отправлен SMS с паролем для активации учетной записи.<br><br>
                        7. Пользователь гарантирует, что данные, предоставленные в ходе регистрации и во время использования веб-сайта, точны, верны и актуальны, и что он имеет право использовать эти данные. Пользователь обязуется обновлять данные в случае их изменения.<br><br>
                        8. Пользователь обязуется хранить в тайне данные доступа к учетной записи и защищать их от доступа неуполномоченных третьих лиц. Пользователь незамедлительно информирует администратора веб-сайта, если ему станет известно о том, что неуполномоченные третьи стороны получили доступ к учетной записи, и, если возможно, незамедлительно меняет данные доступа.<br><br>
                        9. При вводе Пользователем данных о постоянном либо временном месте проживания, система осуществляет прикрепление общеобразовательной школы согласно введенным Пользователем данных о местожительстве по принципу закрепления через микро-участок. Система для подтверждения введенной Пользователем информации о местожительстве, вправе затребовать дополнительные сведения в виде скан копий либо фото вариантов документов для верфикации. <br><br>
                        10. В случае если на одно место в соответствии с установленной квотой претендует менее двух поступающих, то в данном случае в этих государственных общеобразовательных специализированных школах и школах-интернатах не проводятся тесты и не открывается специализированные классы.<br><br>
                        11. Администрация веб-сайта оставляет за собой право вносить изменения в настоящие Правила.<br><br>
                        12. Положения Правил в нынешней редакции применяются до даты вступления в силу новых Правил.<br><br>
                        13. Приложения к настоящим Правилам являются их неотъемлемой частью

                    </p>
                    
                        <h4 class="text-center"> СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ  </h4>
                    <p>
                        Предоставляя свои персональные данные, Пользователь даёт согласие на обработку, хранение и использование своих персональных данных на основании Закона Республики Узбекистан «О персональных данных» ЗРУ-547 от 02.07.2019 г. в следующих целях:
                        <br><br>- Для записи ребенка (подопечного) Пользователя для приёма в 1 класс общеобразовательных школ, находящихся на территории Республики Узбекистан;
                        <br><br>- Для записи ребенка (подопечного) Пользователя в специализированные школы (5 класс) на территории Республики Узбекистан;
                        <br><br>- Для записи ребенка (подопечного) Пользователя в специализированные школы-интернаты (7 класс) на территории Республики Узбекистан;
                        <br><br>Настоящим Пользователь даёт свое согласие на обработку персональных данных, касающихся Пользователя и его несовершеннолетнего ребенка (подопечного), относящихся исключительно перечисленным ниже категориям персональных данных:
                        <br><br>- ПИНФЛ Пользователя;
                        <br><br>- Паспортные данные, гражданство;
                        <br><br>- Адрес местожительства;
                        <br><br>- ПИНФЛ ребенка (подопечного);
                        <br><br>- Данные свидетельства о рождении/данные документа, удостоверяющего личность: ФИО; пол; дата рождения; тип, серия, номер документа удостоверяющего личность, гражданство ребенка (подопечного);
                        <br><br>- Фото- и видеоизображение документов для подтверждения, в случае их отсутствия либо для уточнения расхождений с введенными данными.
                        <br><br>Настоящее согласие предоставляется на осуществление работниками министерства народного образования следующих действий в отношении персональных данных ребенка (подопечного): сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование (только в указанных выше целях), обезличивание, блокирование (не включает возможность ограничения моего доступа к персональным данным ребенка), а также осуществление любых иных действий, предусмотренных действующим законодательством Республики Узбекистан.
                        Обработка персональных данных должна осуществляться с соблюдением требований, установленных законодательством Республики Узбекистан и только для целей, указанных выше. Система осуществляет защиту персональных данных ребенка, принимает необходимые организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, модифицирования, блокирования, копирования, распространения, а также от иных неправомерных действий в отношении данной информации.
                        Обработка персональных данных моего ребенка для любых иных целей и любым иным способом, включая распространение и передачу их иным лицам или иное их разглашение может осуществляться только с моего особого письменного согласия в каждом отдельном случае.
                        Защита внесенной информации осуществляется с соблюдением требований, установленных законодательством Республики Узбекистан. Хранение и обработка информации, а также обмен информацией осуществляются после принятия необходимых мер по защите указанной информации. В случае нарушения требований Закона «О персональных данных» министерство народного образования несет ответственность, предусмотренную действующим законодательством Республики Узбекистан.
                        Данное Согласие действует до достижения целей обработки персональных данных в Министерстве народного образования или до истечения срока хранения информации данного Согласия. Данное Согласие может быть отозвано в любой момент по моему письменному заявлению.
                        Мне разъяснено, что отзыв настоящего согласия может затруднить или сделать невозможным возобновление обработки персональных данных и их подтверждение.
                        Я подтверждаю, что, давая настоящее согласие, я действую по своей воле и в интересах ребенка, законным представителем которого я являюсь.
                        <br><br>Статья 141<sup>2</sup>. Нарушение законодательства о персональных данных
                                Незаконный сбор, систематизация, хранение, изменение, дополнение, использование, предоставление, распространение, передача, обезличивание и уничтожение персональных данных, совершенное после применения административного взыскания за такие же действия, — 
                                наказывается штрафом до пятидесяти базовых расчетных величин или лишением определенного права до трех лет либо исправительными работами до двух лет.
                                Те же действия:
                                а) совершенные по предварительному сговору группой лиц;
                                б) совершенные повторно или опасным рецидивистом;
                                в) совершенные из корыстных или иных низменных побуждений;
                                г) совершенные с использованием служебного положения;
                                д) повлекшие тяжкие последствия, —
                                наказываются штрафом от пятидесяти до ста базовых расчетных величин или исправительными работами от двух до трех лет или ограничением свободы от одного года до трех лет либо лишением свободы до трех лет.
                        <br><br>Статья 46<sup>2</sup>. Нарушение законодательства о персональных данных
                                Незаконный сбор, систематизация, хранение, изменение, дополнение, использование, предоставление, распространение, передача, обезличивание и уничтожение персональных данных —
                                влечет наложение штрафа на граждан от трех до пяти, а на должностных лиц — от пяти до десяти базовых расчетных величин.


                    </p> -->
          </div>
          <div
            v-if="lang == 'uz_cyrl'"
            class="oferta-card"
            style="padding-left:20px;padding-right:20px"
          >
           <p>
              Мазкур ҳужжат Ўзбекистон Республикаси Президенти ҳузуридаги
              Тадбиркорлик субъектларининг ҳуқуқлари ва қонуний манфаатларини
              ҳимоя қилиш бўйича вакилининг “ЯГОНА ДАВЛАТ НАЗОРАТИ” ахборот
              тизимидан (кейинги ўринларда - Тизим) тадбиркорларнинг рўйхатдан
              ўтиши, шахсий кабинет очиши ва ундан фойдаланиш бўйича Ўзбекистон
              Республикаси Фуқаролик кодексининг 367-моддаси ва 369-моддаси
              иккинчи қисмига мувофиқ хизмат кўрсатиш шартномасини (кейинги
              ўринларда – Шартнома) тузиш юзасидан лаёқатли бўлган
              тадбиркорларга (кейинги ўринларда – Фойдаланувчи) расмий таклиф
              ҳисобланади. <br />
              Фойдаланувчи томонидан мазкур офертада белгиланган ҳаракатларнинг
              бажарилиши, у томонидан қуйидаги шартлар сўзсиз қабул
              қилинганлигини англатади (Офертанинг акцепти). Офертани акцепт
              қилган пайтдан шартнома тузилган ҳисобланади. Мазкур шартномада
              тўғридан-тўғри назарда тутилмаган барча ҳолларда, томонлар
              Ўзбекистон Республикаси қонун ҳужжатлари ва иш муомаласи
              одатларига риоя қиладилар.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              1. АТАМА ВА ТАЪРИФЛАР.
            </p>
            <p class="mt-0 pt-0">
              1.1. Мазкур шартномада қуйидаги атама ва таърифлар қўлланилади:
              <br /><span style=" font-weight: bold;"
                >Шахсга доир маълумотларни қайта ишлаш</span
              >
              – шахсга доир маълумотларни йиғиш, тизимлаштириш, сақлаш,
              ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш,
              узатиш, эгасизлантириш ва йўқ қилиш бўйича битта ёки бир нечта
              ҳаракатларни амалга ошириш; <br /><span
                style=" font-weight: bold;"
                >Шахсга доир маълумотлар</span
              >
              – Тизимдан фойдаланиш учун фойдаланувчи томонидан тақдим этилган
              муайян жисмоний шахсга тааллуқли бўлган ёки уни идентификация
              қилиш имконини берадиган ахборот; <br /><span
                style=" font-weight: bold;"
              >
                Хизмат кўрсатувчи Хизмат кўрсатувчи</span
              >- фойдаланувчига  <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзили орқали тадбиркорлик
              субъектлари фаолияти бўйича ваколатли органлар томонидан
              ўтказиладиган текширишлар тўғрисидаги ҳамда уларнинг ҳуқуқ ва
              манфаатларини ҳимоя қилиш билан боғлиқ маълумотларни жойлаштириб
              борувчи ташкилот; <br /><span style=" font-weight: bold;"
                >Фойдаланувчи</span
              >
              – мазкур оферта шартларини қабул қилган ва  <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орақали хизматларни олувчи лаёқатли жисмоний шахс.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              2. АКЦЕПТ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              2.1. Мазкур Офертанинг акцепти  <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти орқали
              авторизациядан ўтиб, хизмат кўратувчи томонидан кўрсатиладиган
              хизматларни олиш орқали амалга оширилади. <br />
              2.2. Мазкур Шартномани тузиш орқали фойдаланувчи хизмат
              курсатувчига амалдаги шахсга доир маълумотлар тўғрисидаги қонун
              ҳужжатлари талабларига мувофиқ, ушбу шартномани бажариш мақсадида,
              автоматизация воситаларидан фойдаланган ёки улардан фойдаланмаган
              ҳолда, ўзининг шахсга доир маълумотларига (шу жумладан, мобиль
              телефон рақами, Ф.И.Ш., рўйхатдан ўтиш билан боғлиқ маълумотлар,
              шунингдек, бошқа шахсга доир маълумотлар) ишлов беришга муддатсиз
              розилигини беради. <br />Шунингдек, ушбу орқали фойдаланувчи
              мазкур шартнома бўйича хизмат кўрсатувчига тақдим этилган шахсга
              доир маълумотларни ушбу шартномани бажариш мақсадида учинчи
              шахсларга тақдим этишга розилик беради. <br />2.3. Хизмат
              кўрсатувчи Фойдаланувчининг шахсга доир маълумотларнинг махфийлиги
              бўйича Ўзбекистон Республикасининг амалдаги қонун ҳужжатлари
              талабларига мувофиқ барча зарур бўлган чораларни кўришини
              Фойдаланувчига кафолатлайди. Фойдаланувчи унинг шахсга доир
              маълумотларига ишлов беришга розилиги (шу жумладан учинчи
              шахсларга ҳам) муддатсиз ҳисобланиб, истаган пайтда шахсга доир
              маълумотларига ишлов беришга розилигини қайтариб олиши мумкин.
              <br />2.4. Тизимдан фойдаланишдан аввал Фойдаланувчи мазкур
              Офертанинг матни билан тўлиқ танишиб чиқиши зарур. Фойдаланувчи
              Офертанинг шартларидан тўлиқ ёки қисман норози бўлса, Тизимдан
              фойдаланишдан бош тортиши зарур.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              3. ШАРТНОМА ПРЕДМЕТИ
            </p>
            <p class="mt-0 pt-0">
              3.1. Фойдаланувчи хизмат кўрсатувчининг  <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзилида
              руйхатдан ўтади. Руйхатдан ўтиш жараёнида шахсий маълумотлар,
              тадбиркорлик субъектнинг юридик манзили ва бошқа реквизитлари
              тўлдирилади. Фойдаланувчи Рўйхатдан ўтиш ва тизимдан фойдаланиш
              пайтида берилган маълумотларнинг аниқ, тўғри ва долзарб эканлигига
              ва ушбу маълумотлардан фойдаланиш ҳуқуқига эга эканлигига кафолат
              беради. Фойдаланувчи маълумотлари ўзгарса, уларни янгилаш
              мажбуриятини олади. <br />3.2. Рўйхатдан ўтгандан кейин
              тадбиркорга шахсий кабинет очилади. Шахсий кабинетда ўз фаолиятида
              рўйхатга олинган текширишлар тўғрисида маълумотлар ҳамда давлат
              назорат функциялари, мажбурий талаблар ва текшириш ҳуқуқи эга
              бўлган мансабдор шахслар реестрлари мавжуд бўлади. Фойдаланувчи
              тизим орқали ушбу маълумотлар ва реестрлардан фойдаланиши ҳамда
              тизимдан рўйхатдан ўтиш ва фойдаланиш жараёнида тизим орқали смс
              хабарномалар олиши мумкин. <br />Шунингдек, шахсий кабинет орқали
              Фойдаланувчи ўз фаолиятида ўтказилаётган текширувларни
              Текширувларни рўйхатга олиш китобида рўйхатга олиши, текширув
              жараёни юзасидан ариза ва шикоят қолдириши, ўз фикрларини йўллай
              олиши мумкин. <br />Шунингдек тизимда видео-дарслар ва қўлланмалар
              жойлаштирилади. Тизим орқали унга жойлаштирилган ресурслардан
              фойдаланиш бепул. <br />Тизимдан фойдаланиш жаранида
              Фойдаланувчига йўлланадиган смс хабарномалар учун Фойдаланувчининг
              мобил алоқа ҳисоб рақамидан мобил алоқа операторлари ва
              контент-провайдерлар томонидан белгиланган нархларга асосан
              қўшимча тўловлар ечиб олиниши мумкин.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              4. ТАРАФЛАР МАЖБУРИЯТЛАРИ
            </p>
            <p class="mt-0 pt-0">
              4.1. Хизмат кўрсатувчи қуйидагиларга мажбур: <br />
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>
              тизимининг узлуксиз ишини таъминлаш (учинчи шахсларнинг айби билан
              юзага келадиган узилиш ҳолатлари бундан мустасно), шахсга доир
              маълумотларнинг махфийлигига риоя қилиш, уларга ноқонуний ишлов
              беришнинг олдини олиш, уларнинг яхлитлиги ва сақланишини
              таъминлайдиган ҳуқуқий, ташкилий ва техник чораларни кўриш.
              <br />4.2. Фойдаланувчи қуйидагиларга мажбур: <br />мазкур
              офертанинг талабларини бажариш, Тизимга уланувчи воситанинг
              ахборот хавфсизлигини мустақил равишда таъминлаш; ўзининг
              лаёқатлиги, шунингдек Тизимдан фирибгарлик ва бошқа ғайриқонуний
              мақсадларда фойдаланмаслигини тасдиқлаш ва кафолатлаш, Хизмат
              кўрсатувчининг розилигини олдиндан олмасдан шартнома бўйича ҳуқуқ
              ва мажбуриятларини учинчи шахсларга бермаслик. <br />4.3. Хизмат
              кўрсатувчи бир томонлама мазкур Офертага ўзгартириш киритишга
              ҳақли, бунда Хизмат кўрсатувчи Фойдаланувчини бу ҳақда Тизим ёки
              СМС-хабар орқали огоҳлантиради.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              5. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ
            </p>
            <p class="mt-0 pt-0">
              5.1. Томонлар мазкур шартноманинг талабларини бажармаганлиги ёки
              лозим даражада бажармаганлиги учун Ўзбекистон Республикаси
              қонунчилигига мувофиқ жавоб берадилар. <br />
              5.2. Фойдаланувчи мазкур шартнома бўйича ўз мажбуриятларини
              бажармаганлиги ёки лозим даражада бажармаганлиги натижасида
              вужудга келган ҳар қандай зарар ёки салбий оқибатларга ўзи жавоб
              беради. <br />
              5.3. Фойдаланувчи хавфсиз бўлмаган уланишлардан фойдаланган ҳолда,
              ёхуд зарарли дастур ва иловалар таҳдиди остида ўзининг шахсга доир
              маълумотларини қурилмага киритишида уларнинг хавфсизлигига тўлиқ
              жавоб беради. <br />
              5.4. Фойдаланувчи томонидан шартнома шартлари бажарилмаганлиги ёки
              лозим даражада бажарилмаганлиги оқибатида Хизмат кўрсатувчи
              шартномада белгиланган мажбуриятларни бажара олмаганлиги учун
              Хизмат кўрсатувчи жавобгар бўлмайди.
              <br />5.5. Фойдаланувчи шартнома шартлари билан ўз вақтида ёки
              буткул танишмаганлиги оқибатида вужудга келадиган зарар учун
              Хизмат кўрсатувчи жавобгар бўлмайди. <br />5.6. Хизмат
              кўрсатувчининг назорат доирасига кирмайдиган сабабларга кўра
              шартнома шартлари лозим даражада бажарилмаслигига Хизмат
              кўрсатувчи жавобгар бўлмайди. <br />Хизмат кўрсатувчининг назорат
              доираси фақат ушбу Тизим хизматларининг ишлаши билан чекланиб,
              учинчи шахсларнинг айби билан вужудга келган узилишларга ёхуд
              давлат органлари ёки суд томонидан қуйилган чеклов ва тақиқларни
              ўз ичига олмайди. <br />5.7. Ҳар бир Томон ушбу шартномада
              белгиланган жавобгарлик чекланганлигини тан олган ҳолда, бошқа
              Томон мазкур шартномани тузишни ва ушбу чекловлар тарафлар
              ўртасидаги келишувлар учун зарур асос эканлигини тан олади. Бунда,
              томонларнинг назоратидан ташқаридаги ҳар қандай шароитлар
              оқибатида, шу жумладан, ҳуқумат фаолияти ёки террористик
              ҳаракатлар, зилзилалар, ёнғинлар, тошқинлар ёки бошқа табиий
              офатлар, иш шароитлари билан боғлиқ вазиятлар, электр
              таъминотидаги узилишлар ва Интернетга уланишидаги бузилишлар, бу
              билан чегараланмаган ҳолда, шунга ўхшаш ҳолатларда томонларнинг
              ҳеч бири шартнома талабларини бажармаганлиги ёки бажаришни
              кечиктирганлиги учун жавоб бермайди.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              6. ШАРТНОМАНИ БЕКОР ҚИЛИШ
            </p>
            <p class="mt-0 pt-0">
              6.1. Фойдаланувчи Тизимдаги ўз
              <span style=" font-weight: bold;"
                >аккаунтини ўчириб, шахсий кабинетдан чиқиб кетган ҳолда</span
              >
              мазкур шартномани ҳар қандай вақтда бажармасликка ҳақли.
              <br />6.2. Мазкур шартнома аккаунт ўчирилган вақтдан бошлаб бекор
              қилинган деб ҳисобланади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              7. КЕЛИШМОВЧИЛИКЛАРНИ КЎРИБ ЧИҚИШ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              7.1. Мазкур шартномадан келиб чиқувчи ҳар қандай низо, ўзаро
              музокаралар орқали, ёхуд Фойдаланувчининг ёзма мурожаати асосида
              шикоят тартибида кўриб чиқилиши зарур. Мурожаатни кўриб чиқиш ва
              ва унинг мазмунига қуйиладиган талаблар амалдаги Ўзбекистон
              Республикаси қонунчилиги билан белгиланади. <br />
              7.2. Агар вужудга келган низо музокаралар ёки шикоят тартибида ҳал
              этилмаса, низо Ўзбекистон Республикасининг амалдаги қонунчилиги
              билан белгиланган тартибда жавобгар жойлашган жойдаги
              тааллуқлилиги бўйича тегишли судда кўриб чиқилиши керак. <br />
              7.3. Фойдаланувчилар шикоятлари билан боғлиқ ишлар ва техник
              кўллаб-қувватлаш хизматлари Фойдаланувчиларга фақат сўровларни
              қабул қилиш, уларга ишлов бериш ва тегишли сўровларга жавобларни
              юбориш орқали амалга оширилади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
             8. БОШҚА ШАРТЛАР
            </p>
            <p class="mt-0 pt-0">
              8.1. Тўғридан-тўғри шартномада назарда тутилмаган ҳолатлар Ўзбекистон Республикасининг амалдаги қонунчилигида белгиланган тартибда амалга оширилади. <br>
              8.2. Ушбу таклифни қабул қилиб, Фойдаланувчи ушбу шартноманинг барча шартларини сўзсиз қабул қилганлигини тасдиқлайди ва уни бажаришни ўз зиммасига олади.
            </p>

            <!-- <p>
                       1. Веб-сайтдан фойдаланиш шартлари, шу жумладан Рўйхатдан ўтиш, билан боғлиқ масалалар ушбу Қоидаларда белгилаб қўйилган. Веб-сайтнинг ҳар бир фойдаланувчиси Қоидаларнинг мазмуни билан танишиши шарт. </p>
                       <p>
                           2. Веб-сайтдан тўлиқ фойдаланиш учун Интернетга уланган қурилма қуйидаги талабларга жавоб бериши керак: <br>
                             a. HTTPS протоколи орқали икки томонлама алоқани таъминлайдиган узлуксиз Интернет уланиш;<br>
                             b. HTML5 стандарти ва каскадли услублар жадвалларини (CSS3) ишлата оладиган тўғри ўрнатилган ва созланган сўнгги версиядаги веб-браузер, масалан, Google Chrome, Mozilla Firefox, Opera, Microsoft Edge, Internet Explorer.
                            <br>d. JavaScript ва cookie файлларни қўллаш ёқиб қўйилган (одатда браузерларда автоматик равишда ёқилган бўлади).
                       </p>
                        <p>
                            3. Фақат тегишли миқдордаги муомала лаёқатига эга бўлган жисмоний шахс, Фойдаланувчи бўлиши мумкин.
                        </p>
                        <p>
                            4. Фойдаланувчи фақат битта ҳисобга олиш ёзуви (аккаунт) эга бўлиши мумкин.
                        </p>
                        <p>
                            5. Ҳисобга олиш ёзуви (аккаунт) рўйхатга олиш учун қуйидагилар талаб етилади:<br>
                            а. Веб-сайтда мавжуд бўлган шаклни ва керакли маълумотлар билан тўлдириш, шу жумладан телефон рақамингизни ва ноёб паролни;<br>
                            б. Қоидалар ва уларнинг иловалари билан танишиб, шартларни қабул қилиш.
                        </p>

                        <p>
                            6. Фойдаланувчини рўйхатдан ўтказиш учун мобил телефон рақамидан фойдаланилади, ҳисобга олиш ёзуви (аккаунт) активлаштириш учун кўрсатилган рақамга пароль билан СМС-хабар юборилади.
                        </p>
                        <p>
                            7. Фойдаланувчи Рўйхатдан ўтиш ва Веб-сайтдан фойдаланиш пайтида берилган маълумотларнинг аниқ, тўғри ва долзарб эканлигига ва ушбу маълумотлардан фойдаланиш ҳуқуқига эга эканлигига кафолат беради. Фойдаланувчи маълумотлари ўзгарса, уларни янгилаш мажбуриятини олади.
                        </p>
                        <p>
                            8. Фойдаланувчи Ҳисобга олиш ёзувига (аккаунтга) кириш маълумотларини махфий сақлаш ва уларни рухсатсиз ваколатга эга бўлмаган учинчи шахслар киришидан ҳимоя қилиш мажбуриятини олади. Aгар Фойдаланувчи ваколатга эга бўлмаган учинчи томонлар Ҳисобга олиш ёзувига (аккаунтга) кириш ҳуқуқини олганлигини билса веб-сайт маъмурига (администраторига) дарҳол хабар беради ва иложи бўлса, кириш маълумотларини дарҳол ўзгартиради.
                        </p>

                        <p>
                            9. Фойдаланувчи томонидан доимий ёки вақтинчалик яшаш жойи тўғрисидаги маълумотлар киритилганда, тизим фойдаланувчи томонидан киритилган яшаш жойи тўғрисидаги маълумотлар асосида умумтаълим мактабини микроҳудуд орқали қўшиш тамойили асосида бириктириб қўяди. Тизим фойдаланувчи томонидан яшаш жойи тўғрисидаги маълумотни тасдиқлаш ва верификация қилиш учун ҳужжатларнинг сканер нусхалари ёки фото нусха кўринишида қўшимча маълумотларни талаб қилиш ҳуқуқига эга.
                        </p>
                        <p>
                            10. Aгар белгиланган квота бўйича бир жойга камида икки нафар талабгор мурожаат қилса (ҳужжат топширса), бу ҳолатда мазкур давлат умумтаълим ихтисослаштирилган мактаб ва мактаб-интернатларда тест синовлари ўтказилмайди ва ихтисослаштирилган синфлар очилмайди.
                        </p>
                        <p>
                            11. Веб-сайт маъмурияти (администрацияси) мазкур Қоидаларга ўзгартириш ҳуқуқини ўз зиммасида қолдиради.
                        </p>
                        <p>
                            12. Қоидаларнинг амалдаги таҳрирдаги низомлари янги Қоидалар кучга киргунга қадар қўлланилади.
                        </p>
                        <p>
                            13. Ушбу Қоидаларга қўшимчалар уларнинг ажралмас қисми саналади.
                        </p>

                        <h4 class="text-center"> ШАХСГА ДОИР МAЪЛУМОТЛAРГA ИШЛОВ БЕРИШ УЧУН РОЗИЛИК  </h4>
                        <p>
                            Ўзининг шахсга доир маълумотларини тақдим етиш орқали Фойдаланувчи ЎРҚ-547-сон Ўзбекистон Республикаси “Шахсга доир маълумотлар тўғрисида”ги Қонуни асосида қуйидаги мақсадларда шахсий маълумотларини қайта ишлаш, сақлаш ва улардан фойдаланишга розилик беради:
                            <br><br>- Фойдаланувчининг боласини (васийликдаги шахсни) Ўзбекистон Республикаси ҳудудида жойлашган умумтаълим мактабларининг 1-синфига қабул қилиш учун рўйхатдан ўтказиш;
                            <br><br>- Фойдаланувчининг боласини (васийликдаги шахсни) Ўзбекистон Республикаси ҳудудидаги ихтисослаштирилган мактабларга (5-синфга) рўйхатдан ўтказиш
                            <br><br>- Фойдаланувчининг боласини (васийликдаги шахсни) Ўзбекистон Республикаси ҳудудидаги ихтисослаштирилган мактаб-интернатларга (7-синфга) рўйхатдан ўтказиш
                            <br><br>Шу билан Фойдаланувчи шахсга доир маълумотларни фақат қуйида келтирилган тоифаларга Фойдаланувчи ва унинг вояга етмаган боласига (васийликдаги шахсга) тааллуқли шахсга доир маълумотларга ишлов беришга розилик беради:
                            <br><br>- Фойдаланувчининг ЖШШИР;
                            <br><br>- Паспорт маълумотлари, фуқаролиги;
                            <br><br>- Яшаш манзили;
                            <br><br>- Боланинг (васийликдаги шахснинг) ЖШШИР;
                            <br><br>- Туғилганлик тўғрисидаги гувоҳноманинг маълумотлари/шахсни тасдиқловчи ҳужжат маълумотлари: исм; жинс; туғилган санаси; шахсни тасдиқловчи ҳужжатнинг тури, серияси, рақами, боланинг (васийликдаги шахснинг) фуқаролиги;
                            <br><br>- Ҳужжатларнинг фото ва видео тасвири уларни тасдиқлаш мақсадида, агар ушбу ҳужжатлар мавжуд бўлмаган тақдирда ёки киритилган маълумотлар билан номувофиқликларни аниқлаштириш учун.
                            <br><br>Ушбу розилик халқ таълими вазирлиги ходимлари томонидан боланинг (васийликдаги шахснинг) шахсга доир маълумотларига нисбатан қуйидаги ҳаракатларни амалга ошириш учун тақдим етилади: йиғиш, тизимлаштириш, тўплаш, сақлаш, аниқлаштириш (янгилаш, ўзгартириш), фойдаланиш (фақат юқорида кўрсатилган мақсадлар учун), эгасизлантириш, блокировкалаш (боланинг шахсий маълумотларига киришни чеклаш имкониятини ўз ичига олмайди), шунингдек, Ўзбекистон Республикасининг амалдаги қонун ҳужжатларида назарда тутилган бошқа ҳаракатларни амалга ошириш.
                            Шахсга доир маълумотларга ишлов бериш Ўзбекистон Республикаси қонун ҳужжатларида белгиланган талабларга риоя етилган ҳолда ва фақат юқорида кўрсатилган мақсадлар учун амалга оширилиши керак. Тизим боланинг шахсга доир маълумотларини ҳимоя қилади, шахсга доир маълумотларга ноқонуний ёки тасодифий киришдан ҳимоя қилиш, йўқ қилиш, ўзгартириш, блокировка қилиш, нусхалаш, тарқатишдан, шунингдек, ушбу маълумотларга нисбатан бошқа ноқонуний хатти-ҳаракатлардан ҳимоя қилиш учун зарур ташкилий ва техник чораларни кўради.
                            Фарзандимнинг шахсга доир маълумотларини бошқа мақсадлар учун ва бошқа ҳар қандай усулда, шу жумладан уларни бошқа шахсларга тарқатиш ва узатиш ёки уларни ошкор қилиш фақат ҳар бир алоҳида ҳолатда менинг махсус ёзма розилигимдан амалга оширилиши мумкин.
                            Киритилган ахборотни ҳимоя қилиш Ўзбекистон Республикаси қонун ҳужжатларида белгиланган талабларга риоя этилган ҳолда амалга оширилади. Aхборотни сақлаш ва қайта ишлаш, шунингдек ахборот алмашиниш ушбу ахборотни ҳимоя қилиш бўйича зарур чоралар кўрилганидан кейин амалга оширилади. “Шахсга доир маълумотлар тўғрисида”ги қонун талаблари бузилган тақдирда халқ таълими вазирлиги Ўзбекистон Республикасининг амалдаги қонунчилигида назарда тутилган жавобгарлик мажбуриятини олади.
                            Ушбу Розилик халқ таълими вазирлигида шахсга доир маълумотларга ишлов бериш мақсадларига эришиш ёки ушбу розиликнинг муддати тугагунга қадар амал қилади. Ушбу Розилик менинг ёзма аризамга биноан исталган вақтда бекор қилиниши мумкин.
                            Менга ушбу розилик бекор қилинса, шахсга доир маълумотларга ишлов беришни ва уларни тасдиқлашни қийинлаштириши ёки имконсиз қилиши мумкинлиги тушунтирилди.
                            Мен ушбу розиликни бериб, ўз хоҳишим билан ва мен боламни қонуний вакили бўлиб ва унинг манфаати учун ҳаракат қилишимни тасдиқлайман.
                            <br><br>141<sup>2</sup>-модда. Шахсга доир маълумотлар тўғрисидаги қонунчиликни бузиш
                                    Шахсга доир маълумотларни қонунга хилоф равишда йиғиш, тизимлаштириш, сақлаш, ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш, узатиш, эгасизлантириш ва йўқ қилиш ўша ҳаракатлар учун маъмурий жазо қўлланилганидан кейин содир этилган бўлса, —
                                    базавий ҳисоблаш миқдорининг эллик бараваригача миқдорда жарима ёки уч йилгача муайян ҳуқуқдан маҳрум қилиш ёхуд икки йилгача ахлоқ тузатиш ишлари билан жазоланади.
                                    Ўша ҳаракатлар:
                                    а) бир гуруҳ шахслар томонидан олдиндан тил бириктириб содир этилган бўлса;
                                    б) такроран ёки хавфли рецидивист томонидан содир этилган бўлса;
                                    в) ғаразли ёки бошқача паст ниятларда содир этилган бўлса;
                                    г) хизмат мавқеидан фойдаланган ҳолда содир этилган бўлса;
                                    д) оғир оқибатларга сабаб бўлса, —
                                    базавий ҳисоблаш миқдорининг эллик бараваридан юз бараваригача миқдорда жарима ёки икки йилдан уч йилгача ахлоқ тузатиш ишлари ёки бир йилдан уч йилгача озодликни чеклаш ёхуд уч йилгача озодликдан маҳрум қилиш билан жазоланади.
                            <br><br>46<sup>2</sup>-модда. Шахсга доир маълумотлар тўғрисидаги қонун ҳужжатларини бузиш
                                    Шахсга доир маълумотларни қонунга хилоф равишда йиғиш, тизимлаштириш, сақлаш, ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш, узатиш, эгасизлантириш ва йўқ қилиш, —
                                    фуқароларга базавий ҳисоблаш миқдорининг уч бараваридан беш бараваригача, мансабдор шахсларга эса — беш бараваридан ўн бараваригача миқдорда жарима солишга сабаб бўлади.
    
                        </p> -->
          </div>
          <div
            v-if="lang == 'uz_latn'"
            class="oferta-card"
            style="padding-left:20px;padding-right:20px"
          >
            <p>
              Мазкур ҳужжат Ўзбекистон Республикаси Президенти ҳузуридаги
              Тадбиркорлик субъектларининг ҳуқуқлари ва қонуний манфаатларини
              ҳимоя қилиш бўйича вакилининг “ЯГОНА ДАВЛАТ НАЗОРАТИ” ахборот
              тизимидан (кейинги ўринларда - Тизим) тадбиркорларнинг рўйхатдан
              ўтиши, шахсий кабинет очиши ва ундан фойдаланиш бўйича Ўзбекистон
              Республикаси Фуқаролик кодексининг 367-моддаси ва 369-моддаси
              иккинчи қисмига мувофиқ хизмат кўрсатиш шартномасини (кейинги
              ўринларда – Шартнома) тузиш юзасидан лаёқатли бўлган
              тадбиркорларга (кейинги ўринларда – Фойдаланувчи) расмий таклиф
              ҳисобланади. <br />
              Фойдаланувчи томонидан мазкур офертада белгиланган ҳаракатларнинг
              бажарилиши, у томонидан қуйидаги шартлар сўзсиз қабул
              қилинганлигини англатади (Офертанинг акцепти). Офертани акцепт
              қилган пайтдан шартнома тузилган ҳисобланади. Мазкур шартномада
              тўғридан-тўғри назарда тутилмаган барча ҳолларда, томонлар
              Ўзбекистон Республикаси қонун ҳужжатлари ва иш муомаласи
              одатларига риоя қиладилар.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              1. АТАМА ВА ТАЪРИФЛАР.
            </p>
            <p class="mt-0 pt-0">
              1.1. Мазкур шартномада қуйидаги атама ва таърифлар қўлланилади:
              <br /><span style=" font-weight: bold;"
                >Шахсга доир маълумотларни қайта ишлаш</span
              >
              – шахсга доир маълумотларни йиғиш, тизимлаштириш, сақлаш,
              ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш,
              узатиш, эгасизлантириш ва йўқ қилиш бўйича битта ёки бир нечта
              ҳаракатларни амалга ошириш; <br /><span
                style=" font-weight: bold;"
                >Шахсга доир маълумотлар</span
              >
              – Тизимдан фойдаланиш учун фойдаланувчи томонидан тақдим этилган
              муайян жисмоний шахсга тааллуқли бўлган ёки уни идентификация
              қилиш имконини берадиган ахборот; <br /><span
                style=" font-weight: bold;"
              >
                Хизмат кўрсатувчи Хизмат кўрсатувчи</span
              >- фойдаланувчига  <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзили орқали тадбиркорлик
              субъектлари фаолияти бўйича ваколатли органлар томонидан
              ўтказиладиган текширишлар тўғрисидаги ҳамда уларнинг ҳуқуқ ва
              манфаатларини ҳимоя қилиш билан боғлиқ маълумотларни жойлаштириб
              борувчи ташкилот; <br /><span style=" font-weight: bold;"
                >Фойдаланувчи</span
              >
              – мазкур оферта шартларини қабул қилган ва  <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орақали хизматларни олувчи лаёқатли жисмоний шахс.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              2. АКЦЕПТ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              2.1. Мазкур Офертанинг акцепти  <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти орқали
              авторизациядан ўтиб, хизмат кўратувчи томонидан кўрсатиладиган
              хизматларни олиш орқали амалга оширилади. <br />
              2.2. Мазкур Шартномани тузиш орқали фойдаланувчи хизмат
              курсатувчига амалдаги шахсга доир маълумотлар тўғрисидаги қонун
              ҳужжатлари талабларига мувофиқ, ушбу шартномани бажариш мақсадида,
              автоматизация воситаларидан фойдаланган ёки улардан фойдаланмаган
              ҳолда, ўзининг шахсга доир маълумотларига (шу жумладан, мобиль
              телефон рақами, Ф.И.Ш., рўйхатдан ўтиш билан боғлиқ маълумотлар,
              шунингдек, бошқа шахсга доир маълумотлар) ишлов беришга муддатсиз
              розилигини беради. <br />Шунингдек, ушбу орқали фойдаланувчи
              мазкур шартнома бўйича хизмат кўрсатувчига тақдим этилган шахсга
              доир маълумотларни ушбу шартномани бажариш мақсадида учинчи
              шахсларга тақдим этишга розилик беради. <br />2.3. Хизмат
              кўрсатувчи Фойдаланувчининг шахсга доир маълумотларнинг махфийлиги
              бўйича Ўзбекистон Республикасининг амалдаги қонун ҳужжатлари
              талабларига мувофиқ барча зарур бўлган чораларни кўришини
              Фойдаланувчига кафолатлайди. Фойдаланувчи унинг шахсга доир
              маълумотларига ишлов беришга розилиги (шу жумладан учинчи
              шахсларга ҳам) муддатсиз ҳисобланиб, истаган пайтда шахсга доир
              маълумотларига ишлов беришга розилигини қайтариб олиши мумкин.
              <br />2.4. Тизимдан фойдаланишдан аввал Фойдаланувчи мазкур
              Офертанинг матни билан тўлиқ танишиб чиқиши зарур. Фойдаланувчи
              Офертанинг шартларидан тўлиқ ёки қисман норози бўлса, Тизимдан
              фойдаланишдан бош тортиши зарур.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              3. ШАРТНОМА ПРЕДМЕТИ
            </p>
            <p class="mt-0 pt-0">
              3.1. Фойдаланувчи хизмат кўрсатувчининг  <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзилида
              руйхатдан ўтади. Руйхатдан ўтиш жараёнида шахсий маълумотлар,
              тадбиркорлик субъектнинг юридик манзили ва бошқа реквизитлари
              тўлдирилади. Фойдаланувчи Рўйхатдан ўтиш ва тизимдан фойдаланиш
              пайтида берилган маълумотларнинг аниқ, тўғри ва долзарб эканлигига
              ва ушбу маълумотлардан фойдаланиш ҳуқуқига эга эканлигига кафолат
              беради. Фойдаланувчи маълумотлари ўзгарса, уларни янгилаш
              мажбуриятини олади. <br />3.2. Рўйхатдан ўтгандан кейин
              тадбиркорга шахсий кабинет очилади. Шахсий кабинетда ўз фаолиятида
              рўйхатга олинган текширишлар тўғрисида маълумотлар ҳамда давлат
              назорат функциялари, мажбурий талаблар ва текшириш ҳуқуқи эга
              бўлган мансабдор шахслар реестрлари мавжуд бўлади. Фойдаланувчи
              тизим орқали ушбу маълумотлар ва реестрлардан фойдаланиши ҳамда
              тизимдан рўйхатдан ўтиш ва фойдаланиш жараёнида тизим орқали смс
              хабарномалар олиши мумкин. <br />Шунингдек, шахсий кабинет орқали
              Фойдаланувчи ўз фаолиятида ўтказилаётган текширувларни
              Текширувларни рўйхатга олиш китобида рўйхатга олиши, текширув
              жараёни юзасидан ариза ва шикоят қолдириши, ўз фикрларини йўллай
              олиши мумкин. <br />Шунингдек тизимда видео-дарслар ва қўлланмалар
              жойлаштирилади. Тизим орқали унга жойлаштирилган ресурслардан
              фойдаланиш бепул. <br />Тизимдан фойдаланиш жаранида
              Фойдаланувчига йўлланадиган смс хабарномалар учун Фойдаланувчининг
              мобил алоқа ҳисоб рақамидан мобил алоқа операторлари ва
              контент-провайдерлар томонидан белгиланган нархларга асосан
              қўшимча тўловлар ечиб олиниши мумкин.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              4. ТАРАФЛАР МАЖБУРИЯТЛАРИ
            </p>
            <p class="mt-0 pt-0">
              4.1. Хизмат кўрсатувчи қуйидагиларга мажбур: <br />
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>
              тизимининг узлуксиз ишини таъминлаш (учинчи шахсларнинг айби билан
              юзага келадиган узилиш ҳолатлари бундан мустасно), шахсга доир
              маълумотларнинг махфийлигига риоя қилиш, уларга ноқонуний ишлов
              беришнинг олдини олиш, уларнинг яхлитлиги ва сақланишини
              таъминлайдиган ҳуқуқий, ташкилий ва техник чораларни кўриш.
              <br />4.2. Фойдаланувчи қуйидагиларга мажбур: <br />мазкур
              офертанинг талабларини бажариш, Тизимга уланувчи воситанинг
              ахборот хавфсизлигини мустақил равишда таъминлаш; ўзининг
              лаёқатлиги, шунингдек Тизимдан фирибгарлик ва бошқа ғайриқонуний
              мақсадларда фойдаланмаслигини тасдиқлаш ва кафолатлаш, Хизмат
              кўрсатувчининг розилигини олдиндан олмасдан шартнома бўйича ҳуқуқ
              ва мажбуриятларини учинчи шахсларга бермаслик. <br />4.3. Хизмат
              кўрсатувчи бир томонлама мазкур Офертага ўзгартириш киритишга
              ҳақли, бунда Хизмат кўрсатувчи Фойдаланувчини бу ҳақда Тизим ёки
              СМС-хабар орқали огоҳлантиради.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              5. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ
            </p>
            <p class="mt-0 pt-0">
              5.1. Томонлар мазкур шартноманинг талабларини бажармаганлиги ёки
              лозим даражада бажармаганлиги учун Ўзбекистон Республикаси
              қонунчилигига мувофиқ жавоб берадилар. <br />
              5.2. Фойдаланувчи мазкур шартнома бўйича ўз мажбуриятларини
              бажармаганлиги ёки лозим даражада бажармаганлиги натижасида
              вужудга келган ҳар қандай зарар ёки салбий оқибатларга ўзи жавоб
              беради. <br />
              5.3. Фойдаланувчи хавфсиз бўлмаган уланишлардан фойдаланган ҳолда,
              ёхуд зарарли дастур ва иловалар таҳдиди остида ўзининг шахсга доир
              маълумотларини қурилмага киритишида уларнинг хавфсизлигига тўлиқ
              жавоб беради. <br />
              5.4. Фойдаланувчи томонидан шартнома шартлари бажарилмаганлиги ёки
              лозим даражада бажарилмаганлиги оқибатида Хизмат кўрсатувчи
              шартномада белгиланган мажбуриятларни бажара олмаганлиги учун
              Хизмат кўрсатувчи жавобгар бўлмайди.
              <br />5.5. Фойдаланувчи шартнома шартлари билан ўз вақтида ёки
              буткул танишмаганлиги оқибатида вужудга келадиган зарар учун
              Хизмат кўрсатувчи жавобгар бўлмайди. <br />5.6. Хизмат
              кўрсатувчининг назорат доирасига кирмайдиган сабабларга кўра
              шартнома шартлари лозим даражада бажарилмаслигига Хизмат
              кўрсатувчи жавобгар бўлмайди. <br />Хизмат кўрсатувчининг назорат
              доираси фақат ушбу Тизим хизматларининг ишлаши билан чекланиб,
              учинчи шахсларнинг айби билан вужудга келган узилишларга ёхуд
              давлат органлари ёки суд томонидан қуйилган чеклов ва тақиқларни
              ўз ичига олмайди. <br />5.7. Ҳар бир Томон ушбу шартномада
              белгиланган жавобгарлик чекланганлигини тан олган ҳолда, бошқа
              Томон мазкур шартномани тузишни ва ушбу чекловлар тарафлар
              ўртасидаги келишувлар учун зарур асос эканлигини тан олади. Бунда,
              томонларнинг назоратидан ташқаридаги ҳар қандай шароитлар
              оқибатида, шу жумладан, ҳуқумат фаолияти ёки террористик
              ҳаракатлар, зилзилалар, ёнғинлар, тошқинлар ёки бошқа табиий
              офатлар, иш шароитлари билан боғлиқ вазиятлар, электр
              таъминотидаги узилишлар ва Интернетга уланишидаги бузилишлар, бу
              билан чегараланмаган ҳолда, шунга ўхшаш ҳолатларда томонларнинг
              ҳеч бири шартнома талабларини бажармаганлиги ёки бажаришни
              кечиктирганлиги учун жавоб бермайди.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              6. ШАРТНОМАНИ БЕКОР ҚИЛИШ
            </p>
            <p class="mt-0 pt-0">
              6.1. Фойдаланувчи Тизимдаги ўз
              <span style=" font-weight: bold;"
                >аккаунтини ўчириб, шахсий кабинетдан чиқиб кетган ҳолда</span
              >
              мазкур шартномани ҳар қандай вақтда бажармасликка ҳақли.
              <br />6.2. Мазкур шартнома аккаунт ўчирилган вақтдан бошлаб бекор
              қилинган деб ҳисобланади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              7. КЕЛИШМОВЧИЛИКЛАРНИ КЎРИБ ЧИҚИШ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              7.1. Мазкур шартномадан келиб чиқувчи ҳар қандай низо, ўзаро
              музокаралар орқали, ёхуд Фойдаланувчининг ёзма мурожаати асосида
              шикоят тартибида кўриб чиқилиши зарур. Мурожаатни кўриб чиқиш ва
              ва унинг мазмунига қуйиладиган талаблар амалдаги Ўзбекистон
              Республикаси қонунчилиги билан белгиланади. <br />
              7.2. Агар вужудга келган низо музокаралар ёки шикоят тартибида ҳал
              этилмаса, низо Ўзбекистон Республикасининг амалдаги қонунчилиги
              билан белгиланган тартибда жавобгар жойлашган жойдаги
              тааллуқлилиги бўйича тегишли судда кўриб чиқилиши керак. <br />
              7.3. Фойдаланувчилар шикоятлари билан боғлиқ ишлар ва техник
              кўллаб-қувватлаш хизматлари Фойдаланувчиларга фақат сўровларни
              қабул қилиш, уларга ишлов бериш ва тегишли сўровларга жавобларни
              юбориш орқали амалга оширилади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
             8. БОШҚА ШАРТЛАР
            </p>
            <p class="mt-0 pt-0">
              8.1. Тўғридан-тўғри шартномада назарда тутилмаган ҳолатлар Ўзбекистон Республикасининг амалдаги қонунчилигида белгиланган тартибда амалга оширилади. <br>
              8.2. Ушбу таклифни қабул қилиб, Фойдаланувчи ушбу шартноманинг барча шартларини сўзсиз қабул қилганлигини тасдиқлайди ва уни бажаришни ўз зиммасига олади.
            </p>

            <!-- <p>
                        1. Veb-saytdan foydalanish shartlari, shu jumladan Roʻyxatdan oʻtish, bilan bogʻliq masalalar ushbu Qoidalarda belgilab qoʻyilgan. Veb-saytning har bir foydalanuvchisi Qoidalarning mazmuni bilan tanishishi shart.
                    </p>
                    <p>
                        2. Veb-saytdan toʻliq foydalanish uchun Internetga ulangan qurilma quyidagi talablarga javob berishi kerak:
                        <br>
                        a. HTTPS protokoli orqali ikki tomonlama aloqani taʼminlaydigan uzluksiz Internet ulanish;<br>
                        b. HTML5 standarti va kaskadli uslublar jadvallarini (CSS3) ishlata oladigan toʻgʻri oʻrnatilgan va sozlangan soʻnggi versiyadagi veb-brauzer, masalan, Google Chrome, Mozilla Firefox, Opera, Microsoft Edge, Internet Explorer.
                        <br>d. JavaScript va cookies fayllarni qoʻllash yoqib qoʻyilgan (odatda brauzerlarda avtomatik ravishda yoqilgan boʻladi).
                    </p>
                    <p>
                        3. Faqat tegishli miqdordagi muomala layoqatiga ega boʻlgan jismoniy shaxs, Foydalanuvchi boʻlishi mumkin.
                    </p>
                    <p>
                        4. Foydalanuvchi faqat bitta hisobga olish yozuvi (akkaunt) ega boʻlishi mumkin.
                    </p>
                    <p>
                        5. Hisobga olish yozuvi (akkaunt) roʻyxatga olish uchun quyidagilar talab etiladi:<br>
                        a. Veb-saytda mavjud boʻlgan shaklni va kerakli maʼlumotlar bilan toʻldirish, shu jumladan telefon raqamingizni va noyob parolni;<br>
                        b. Qoidalar va ularning ilovalari bilan tanishib, shartlarni qabul qilish.
                    </p>
                    <p>
                        6. Foydalanuvchini ro'yxatdan o'tkazish uchun mobil telefon raqamidan foydalaniladi, hisobga olish yozuvi (akkaunt) aktivlashtirish uchun ko'rsatilgan raqamga parol bilan SMS-xabar yuboriladi.
                    </p>
                    <p>
                        7. Foydalanuvchi Roʻyxatdan oʻtish va Veb-saytdan foydalanish paytida berilgan maʼlumotlarning aniq, toʻgʻri va dolzarb ekanligiga va ushbu maʼlumotlardan foydalanish huquqiga ega ekanligiga kafolat beradi. Foydalanuvchi maʼlumotlari oʻzgarsa, ularni yangilash majburiyatini oladi.
                    </p>
                    <p>
                        8. Foydalanuvchi Hisobga olish yozuviga (akkauntga) kirish maʼlumotlarini maxfiy saqlash va ularni ruxsatsiz vakolatga ega boʻlmagan uchinchi shaxslar kirishidan himoya qilish majburiyatini oladi. Agar Foydalanuvchi vakolatga ega boʻlmagan uchinchi tomonlar Hisobga olish yozuviga (akkauntga) kirish huquqini olganligini bilsa veb-sayt maʼmuriga (administratoriga) darhol xabar beradi va iloji boʻlsa, kirish maʼlumotlarini darhol oʻzgartiradi.
                    </p>
                    <p>
                        9. Foydalanuvchi tomonidan doimiy yoki vaqtinchalik yashash joyi toʻgʻrisidagi maʼlumotlar kiritilganda, tizim foydalanuvchi tomonidan kiritilgan yashash joyi toʻgʻrisidagi maʼlumotlar asosida umumtaʼlim maktabini mikrohudud orqali qoʻshish tamoyili asosida biriktirib qoʻyadi. Tizim foydalanuvchi tomonidan yashash joyi toʻgʻrisidagi maʼlumotni tasdiqlash va verifikatsiya qilish uchun hujjatlarning skaner nusxalari yoki foto nusxa koʻrinishida qoʻshimcha maʼlumotlarni talab qilish huquqiga ega.
                    </p>
                    <p>
                        10. Agar belgilangan kvota bo'yicha bir joyga kamida ikki nafar talabgor murojaat qilsa (hujjat topshirsa), bu holatda mazkur davlat umumta'lim ixtisoslashtirilgan maktab va maktab-internatlarda test sinovlari o'tkazilmaydi va ixtisoslashtirilgan sinflar ochilmaydi.
                    </p>
                    <p>
                        11. Veb-sayt maʼmuriyati (administratsiyasi) mazkur Qoidalarga oʻzgartirish huquqini oʻz zimmasida qoldiradi.
                    </p>
                    <p>
                        12. Qoidalarning amaldagi tahrirdagi nizomlari yangi Qoidalar kuchga kirgunga qadar qoʻllaniladi.
                    </p>

                    <p>
                        13. Ushbu Qoidalarga qoʻshimchalar ularning ajralmas qismi sanaladi.
                    </p>
                    <h4 class="text-center my-text-primary">
                        SHAXSGA DOIR MAʼLUMOTLARGA ISHLOV BERISH UCHUN ROZILIK
                    </h4>
                    <p>
                        Oʻzining shaxsga doir maʼlumotlarini taqdim etish orqali Foydalanuvchi O’RQ-547-son O’zbekiston Respublikasi “Shaxsga doir maʼlumotlar toʻgʻrisida”gi Qonuni asosida quyidagi maqsadlarda shaxsiy maʼlumotlarini qayta ishlash, saqlash va ulardan foydalanishga rozilik beradi:
<br><br>- Foydalanuvchining bolasini (vasiylikdagi shaxsni) Oʻzbekiston Respublikasi hududida joylashgan umumtaʼlim maktablarining 1-sinfiga qabul qilish uchun roʻyxatdan o’tkazish;
<br><br>- Foydalanuvchining bolasini (vasiylikdagi shaxsni) Oʻzbekiston Respublikasi hududidagi ixtisoslashtirilgan maktablarga (5-sinfga) ro'yxatdan oʻtkazish
<br><br>- Foydalanuvchining bolasini (vasiylikdagi shaxsni) Oʻzbekiston Respublikasi hududidagi ixtisoslashtirilgan maktab-internatlarga (7-sinfga) roʻyxatdan oʻtkazish
<br><br>Shu bilan Foydalanuvchi shaxsga doir maʼlumotlarni faqat quyida keltirilgan toifalarga Foydalanuvchi va uning voyaga yetmagan bolasiga (vasiylikdagi shaxsga) taalluqli shaxsga doir maʼlumotlarga ishlov berishga rozilik beradi:
<br><br>- Foydalanuvchining JShShIR;
<br><br>- Pasport maʼlumotlari, fuqaroligi;
<br><br>- Yashash manzili;
<br><br>- Bolaning (vasiylikdagi shaxsning) JShShIR;
<br><br>- Tugʻilganlik toʻgʻrisidagi guvohnomaning maʼlumotlari/shaxsni tasdiqlovchi hujjat maʼlumotlari: ism; jins; tug'ilgan sanasi; shaxsni tasdiqlovchi hujjatning turi, seriyasi, raqami, bolaning (vasiylikdagi shaxsning) fuqaroligi;
<br><br>- Hujjatlarning foto va video tasviri ularni tasdiqlash maqsadida, agar ushbu hujjatlar mavjud boʼlmagan taqdirda yoki kiritilgan maʼlumotlar bilan nomuvofiqliklarni aniqlashtirish uchun.
<br><br>Ushbu rozilik xalq taʼlimi vazirligi xodimlari tomonidan bolaning (vasiylikdagi shaxsning) shaxsga doir maʼlumotlariga nisbatan quyidagi harakatlarni amalga oshirish uchun taqdim etiladi: yigʻish, tizimlashtirish, toʻplash, saqlash, aniqlashtirish (yangilash, oʻzgartirish), foydalanish (faqat yuqorida koʻrsatilgan maqsadlar uchun), egasizlantirish, blokirovkalash (bolaning shaxsiy maʼlumotlariga kirishni cheklash imkoniyatini oʻz ichiga olmaydi), shuningdek, Oʻzbekiston Respublikasining amaldagi qonun hujjatlarida nazarda tutilgan boshqa harakatlarni amalga oshirish.
Shaxsga doir maʼlumotlarga ishlov berish Oʻzbekiston Respublikasi qonun hujjatlarida belgilangan talablarga rioya etilgan holda va faqat yuqorida ko'rsatilgan maqsadlar uchun amalga oshirilishi kerak. Tizim bolaning shaxsga doir maʼlumotlarini himoya qiladi, shaxsga doir maʼlumotlarga noqonuniy yoki tasodifiy kirishdan himoya qilish, yoʻq qilish, oʻzgartirish, blokirovka qilish, nusxalash, tarqatishdan, shuningdek, ushbu maʼlumotlarga nisbatan boshqa noqonuniy xatti-harakatlardan himoya qilish uchun zarur tashkiliy va texnik choralarni koʻradi.
Farzandimning shaxsga doir maʼlumotlarini boshqa maqsadlar uchun va boshqa har qanday usulda, shu jumladan ularni boshqa shaxslarga tarqatish va uzatish yoki ularni oshkor qilish faqat har bir alohida holatda mening maxsus yozma roziligimdan amalga oshirilishi mumkin.
Kiritilgan axborotni himoya qilish Oʻzbekiston Respublikasi qonun hujjatlarida belgilangan talablarga rioya etilgan holda amalga oshiriladi. Axborotni saqlash va qayta ishlash, shuningdek axborot almashinish ushbu axborotni himoya qilish boʻyicha zarur choralar koʻrilganidan keyin amalga oshiriladi. “Shaxsga doir maʼlumotlar toʻgʻrisida”gi qonun talablari buzilgan taqdirda xalq taʼlimi vazirligi Oʻzbekiston Respublikasining amaldagi qonunchiligida nazarda tutilgan javobgarlik majburiyatini oladi.
Ushbu Rozilik xalq taʼlimi vazirligida shaxsga doir maʼlumotlarga ishlov berish maqsadlariga erishish yoki ushbu rozilikning muddati tugagunga qadar amal qiladi. Ushbu Rozilik mening yozma arizamga binoan istalgan vaqtda bekor qilinishi mumkin.
Menga ushbu rozilik bekor qilinsa, shaxsga doir ma`lumotlarga ishlov berishni va ularni tasdiqlashni qiyinlashtirishi yoki imkonsiz qilishi mumkinligi tushuntirildi.
Men ushbu rozilikni berib, o'z xohishim bilan va men bolamni qonuniy vakili bo'lib va uning manfaati uchun harakat qilishimni tasdiqlayman.
<br><br>141<sup>2</sup>-modda. Shaxsga doir ma’lumotlar to‘g‘risidagi qonunchilikni buzish Shaxsga doir ma’lumotlarni qonunga xilof ravishda yig‘ish, tizimlashtirish, saqlash, o‘zgartirish, to‘ldirish, ulardan foydalanish, ularni berish, tarqatish, uzatish, egasizlantirish va yo‘q qilish o‘sha harakatlar uchun ma’muriy jazo qo‘llanilganidan keyin sodir etilgan bo‘lsa, — bazaviy hisoblash miqdorining ellik baravarigacha miqdorda jarima yoki uch yilgacha muayyan huquqdan mahrum qilish yoxud ikki yilgacha axloq tuzatish ishlari bilan jazolanadi. O‘sha harakatlar: a) bir guruh shaxslar tomonidan oldindan til biriktirib sodir etilgan bo‘lsa;
 b) takroran yoki xavfli retsidivist tomonidan sodir etilgan bo‘lsa; v) g‘arazli yoki boshqacha past niyatlarda sodir etilgan bo‘lsa; g) xizmat mavqeidan foydalangan holda sodir etilgan bo‘lsa; d) og‘ir oqibatlarga sabab bo‘lsa, — bazaviy hisoblash miqdorining ellik baravaridan yuz baravarigacha miqdorda jarima yoki ikki yildan uch yilgacha axloq tuzatish ishlari yoki bir yildan uch yilgacha ozodlikni cheklash yoxud uch yilgacha ozodlikdan mahrum qilish bilan jazolanadi.
                   <br><br>46<sup>2</sup>-modda.Shaxsga doir ma’lumotlar to‘g‘risidagi qonun hujjatlarini buzish
Shaxsga doir ma’lumotlarni qonunga xilof ravishda yig‘ish, tizimlashtirish, saqlash, o‘zgartirish, to‘ldirish, ulardan foydalanish, ularni berish, tarqatish, uzatish, egasizlantirish va yo‘q qilish, —
fuqarolarga bazaviy hisoblash miqdorining uch baravaridan besh baravarigacha, mansabdor shaxslarga esa — besh baravaridan o‘n baravarigacha miqdorda jarima solishga sabab bo‘ladi.

                    </p> -->
          </div>
          <div class="soglasen">
            <custom-button @click.native="ReturnRegister">
              <span style="margin-left:48px;margin-right:48px">
                {{ $t("agree") }}
              </span>
            </custom-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import customButton from "../components/elements/customButton.vue";
export default {
  components: { BRow, BCol, customButton },
  data() {
    return {
      lang: localStorage.getItem("locale") || "uz_cyrl",
    };
  },
  methods: {
    ReturnRegister() {
      this.$router.push({ name: "Register" });
    },
  },
};
</script>

<style></style>
